<template>
    <div :class="[LangeuageAgent === 'CH' ? 'font18' : 'font12']" class="MultiplePanel flex content-between">
        <div class="flex1 flex">
            <div v-for="(item,index) in CurrentPanelContent" :key="index"
                 :class="[item.length === 1 ? 'centent-end' : 'content-between']"
                 class="CurrentPanelContent flex1 flex column items colorfff">
                <span v-for="(l,i) in item" :key="i" class="flex items-center"><font
                        :class="SetClass(l)">{{Trans(l,i)}}</font>：{{CurrentData[index][i]}}</span>
            </div>
        </div>
        <div class="flex items-center mar15">{{Lang['XH']}}<!--限红--> :{{TableInfo.limitRed}}</div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'MultiplePanel',
        props: {
            'TableInfo': {
                default() {
                    return {}
                },
                type: Object
            }
        },
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                'Panel': {
                    'Baccarat': [['ZJ', 'XJ', 'HJ'], ['ZD', 'XD', 'YXXJ']],//['庄', '闲', '和'], ['庄对', '闲对', '靴局']
                    'LonghuLake': [['LHL', 'LHH', 'HJ'], ['YXXJ']],//['龙', '虎', '和'], ['靴局']
                    'Cattle': [['XJ1', 'XJ2', 'XJ3'], ['YXYJ', 'YXXJ']],//['闲一', '闲二', '闲三'], ['押金', '靴局']
                    'ThreeCounsellors': [['XJ1', 'XJ2', 'XJ3'], ['YXYJ', 'YXXJ']],//['闲一', '闲二', '闲三'], ['押金', '靴局']
                    'SingleDouble': [['DSD', 'DSS', 'YXXJ']],//['单', '双', '靴局']
                    'SameSize': [['DXTD', 'DXTX', 'DXTT'], ['YXXJ']],//['大', '小', '同'], ['靴局']
                    'PushBobbin': [['TTZT1', 'TTZT2', 'TTZT3'], ['TTZT4', 'YXYJ', 'YXXJ']]//['筒一', '筒二', '筒三'], ['筒四', '押金', '靴局']
                },
                'CurrentPanelContent': [],
                'CurrentData': []
            }
        },
        created() {
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    if (!n.hasOwnProperty('roadList')) return;
                    this.CurrentPanelContent = this.Clone(this.Panel[n.type]);
                    let {lotteryPool, chang, ci} = n;
                    lotteryPool = lotteryPool || [0, 0, 0, 0, 0];   //防止数组为null
                    if (n.type === 'Baccarat') {  //百家乐
                        this.CurrentData = [[lotteryPool[0], lotteryPool[1], lotteryPool[2]], [lotteryPool[3], lotteryPool[4]]];
                    } else if (n.type === 'LonghuLake' || n.type === 'SameSize') {   //龙虎  大小点
                        this.CurrentData = [[lotteryPool[0], lotteryPool[1], lotteryPool[2]], []];
                    } else if (n.type === 'SingleDouble') { //单双
                        this.CurrentData = [[lotteryPool[1], lotteryPool[0], `${chang} / ${ci}`], []];
                    } else if (n.type === 'Cattle' || n.type === 'ThreeCounsellors') {
                        let _arr = [0, 0, 0];
                        let fn = (a, b, c, d, e) => {
                            if (c >= a && c <= b) _arr[d] += e;
                        };
                        lotteryPool.forEach((item, index) => {
                            fn(0, 2, index, 0, item);
                            fn(3, 5, index, 1, item);
                            fn(6, 8, index, 2, item);
                        });
                        this.CurrentData = [[_arr[0], _arr[1], _arr[2]], [lotteryPool[lotteryPool.length - 1]]];
                    } else if (n.type === 'PushBobbin') {
                        let _arr = [0, 0, 0, 0];
                        let fn = (a, b, c, d, e) => {
                            if (c >= a && c <= b) _arr[d] += e;
                        };
                        lotteryPool.forEach((item, index) => {
                            fn(0, 2, index, 0, item);
                            fn(3, 5, index, 1, item);
                            fn(6, 8, index, 2, item);
                            fn(9, 11, index, 3, item);
                        });
                        this.CurrentData = [[_arr[0], _arr[1], _arr[2]], [_arr[3], lotteryPool[lotteryPool.length - 1]]];
                    }
                    this.CurrentData[1].push(`${chang} / ${ci}`);
                }, deep: true
            }
        },
        methods: {
            SetClass(str) {
                let name = '';
                //'庄', '庄对', '龙', '双', '大'
                if (['ZJ', 'ZD', 'LHL', 'DSS', 'DXTD'].includes(str)) name = 'colorz';
                //'闲', '闲对', '虎', '单', '小'
                if (['XJ', 'XD', 'LHH', 'DSD', 'DXTX'].includes(str)) name = 'colorx';
                //str.indexOf('筒') >= 0
                if (str.indexOf('TTZT') >= 0) name = 'colordxs';
                //'和', '同'
                if (['HJ', 'DXTT'].includes(str)) name = 'colorh';
                if (str === '总数') name = 'colorzs';
                //'闲一', '闲二', '闲三', '押金'
                if (['XJ1', 'XJ2', 'XJ3', 'YXYJ'].includes(str)) name = 'colordxs';
                // if (localStorage.type === 'Cattle' || localStorage.type === 'SingleDouble') name += ' flex1 items-center';
                return name;
            },
            Trans(item, index) {
                let Str = this.Lang[item];
                if (item.indexOf('XJ') >= 0) {
                    if (item !== 'XJ') Str = this.Lang['XJ'] + (item.split('XJ')[1]);
                }
                if (item.indexOf('TTZT') >= 0) {
                    Str = this.Lang['TTZT'] + (item.split('TTZT')[1]);
                }
                return Str;
            }
        }
    }
</script>

<style lang="less">
    .MultiplePanel {
        height: .75rem;

        .CurrentPanelContent {
            padding-left: .05rem;

            span {
                height: 33.33%;
            }
        }

        > div:last-child {
            color: #af8868;
        }
    }
</style>
